<template>
  <v-main data-draftList>
    <v-container>
      <v-dialog
        overlay-color="white"
        overlay-opacity="0.87"
        v-model="deleteDialog"
        width="900"
        height="500"
      >
        <v-card
          tile
          class="d-flex align-center pa-5"
          width="900"
          height="500"
          data-draft="delete"
        >
          <v-btn
            absolute
            top
            right
            icon
            tile
            id="close"
            @click="deleteDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card flat tile class="ma-auto text-center" width="400">
            <div class="text-h5 font-weight-medium">
              {{ $t("draftList.delete.title") }}
            </div>
            <div class="text-subtitle-1 grey--text font-weight-regular">
              {{ $t("draftList.delete.description") }}
            </div>
            <div class="d-flex justify-center py-4">
              <v-btn
                id="cancel"
                class="text-none font-weight-regular"
                text
                outlined
                @click="deleteDialog = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <span class="px-2" />
              <v-btn
                id="delete"
                color="error"
                class="text-none font-weight-regular"
                @click="deleteDraft"
                depressed
              >
                {{ $t("delete") }}
              </v-btn>
            </div>
          </v-card>
        </v-card>
      </v-dialog>

      <v-toolbar color="white" flat>
        <v-toolbar-title class="text-h4 font-weight-medium">
          {{ $t("draftList.title") }}
        </v-toolbar-title>
        <v-spacer />
        <SectionButton
          :to="routes.newDraft"
          data-draft="new"
          class="primary--text"
        >
          {{ $t("new schedule") }}
        </SectionButton>
      </v-toolbar>

      <v-list two-line class="py-8">
        <v-divider></v-divider>
        <template v-for="(draft, i) in drafts">
          <v-list-item :key="draft.id" :data-draft-item="draft.id">
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-bold">
                <router-link id="title" :to="routes.getDraft(draft.id)">
                  {{ draft.title }}
                </router-link>
              </v-list-item-title>

              <v-list-item-subtitle class="text-body-2">
                <router-link :to="routes.getDraft(draft.id)">
                  <span id="range">
                    <template v-if="draft.range">
                      {{ getRangeLabel(draft.range) }}
                    </template>
                    <template v-else>
                      <i>{{ $t("unscheduled") }}</i>
                    </template>
                  </span>
                  <span id="updated">
                    {{ $t("draftList.lastUpdate", { at: draft.updated }) }}
                  </span>
                </router-link>
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn small icon tile v-bind="attrs" v-on="on" id="more">
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense :data-draft-menu="draft.id">
                    <v-list-item :to="routes.getDraft(draft.id)" id="edit">
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="text-body-2 font-weight-regular"
                        >
                          {{ $t("edit") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :to="routes.copyDraft(draft.id)"
                      id="duplicate"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="text-body-2 font-weight-regular"
                        >
                          {{ $t("duplicate") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="confirmDelete(draft.id)" id="delete">
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="text-body-2 font-weight-regular"
                        >
                          {{ $t("delete") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`div-${i}`"></v-divider>
        </template>
      </v-list>

      <v-card-actions v-if="last">
        <v-btn
          block
          text
          outlined
          class="pa-4 text-none text-h6"
          @click="loadPage"
          data-draft="more"
          :disabled="loading"
        >
          {{ $t("more") }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-main>
</template>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
span:not(:first-child):before {
  content: "·";
}
</style>

<script>
import { orderBy, limit, startAt } from "@firebase/firestore";

import moment from "moment";
import SectionButton from "@/components/settings/utils/SectionButton";
import BaseDates from "@/components/draft/BaseDates";
export default {
  name: "DraftList",
  extends: BaseDates,
  components: {
    SectionButton,
  },
  props: {
    pageSize: { type: Number, default: 10 },
  },
  data: () => ({
    loading: false,
    ref: null,
    routes: null,
    drafts: [],
    last: null,
    deleteDialog: false,
    deleteDraftId: "",
  }),
  methods: {
    async loadPage() {
      this.loading = true;

      try {
        const constraints = [
          orderBy("updated", "desc"),
          limit(this.pageSize + 1),
        ];
        if (this.last) constraints.push(startAt(this.last));
        const result = await this.$firebase.getDocs(
          this.$firebase.query(this.ref, ...constraints)
        );
        this.last =
          result.size === this.pageSize + 1 ? result.docs.at(-1) : null;
        this.drafts.push(
          ...(await Promise.all(
            result.docs.slice(0, this.pageSize).map(async (d) => {
              const draft = {
                id: d.id,
                title: d.get("title"),
                template: d.get("template"),
                updated: moment(d.get("updated").toDate()).fromNow(),
              };
              const r = await this.$firebase.getDoc(
                this.$firebase.doc(d.ref, "pub/range")
              );
              if (r.exists()) {
                draft.range = {
                  beg: r.get("range.beg").toDate(),
                  end: r.get("range.end").toDate(),
                };
              }
              return draft;
            })
          ))
        );
      } finally {
        this.loading = false;
      }
    },
    confirmDelete(draftId) {
      this.deleteDialog = true;
      this.deleteDraftId = draftId;
    },
    async deleteDraft() {
      try {
        await this.$firebase.deleteDoc(
          this.$firebase.doc(this.ref, this.deleteDraftId)
        );
        this.drafts = this.drafts.filter((d) => d.id !== this.deleteDraftId);
      } finally {
        this.deleteDialog = false;
      }
    },
  },
  watch: {
    deleteDialog(value) {
      if (!value) {
        this.deleteDraftId = "";
      }
    },
  },
  created() {
    const baseRef = this.$route.matched
      .find((r) => r.meta.ref)
      .meta.ref(this.$route);
    this.ref = this.$firebase.collection(baseRef, "drafts");
    this.routes = this.$route.matched
      .find((r) => r.meta.routes)
      .meta.routes(this.$route);
  },
  mounted() {
    this.loadPage();
  },
};
</script>
