<template>
  <v-btn
    color="accent"
    class="text-none font-weight-regular"
    text
    outlined
    :disabled="disabled"
    @click="$emit('click')"
    :to="to"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "SectionButton",
  props: {
    disabled: { type: Boolean, default: false },
    to: {},
  },
};
</script>
